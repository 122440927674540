<template>
    <div class="organisationMembers w-full">
        <SuccessMessage/>

        <LTabBody>
            <template #body>
                <div class="flex justify-between">
                    <h3 class="heading3">Members</h3>
                </div>
                <div class="flex flex-row flex-wrap place-content-center justify-start">
                    <div
                            v-for="(member, index) in memberList"
                            :key="index"
                    >
                        <div class="flex flex-col items-center p-4 w-64">
                            <img
                                    :src="member.imageUrl ?? '/placeholders/profilePlaceholder.svg'"
                                    class="w-24 h-24 object-cover rounded-full"
                            />
                            <p
                                    class="mt-2 text-lg font-medium"
                                    style="
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              text-overflow: ellipsis;
                "
                            >
                                {{ member.name }}
                            </p>
                            <p
                                    class="mt-2 text-xs font-medium"
                                    style="
                              display: -webkit-box;
                              -webkit-line-clamp: 1;
                              -webkit-box-orient: vertical;
                              overflow: hidden;
                              text-overflow: ellipsis;
                "
                            >
                                {{ member.email }}
                            </p>


                            <p class="mt-2 text-xs xt-gray-500 dark:text-gray-400 dark:border-gray-700">
                                {{ member._userPermission >= manageAsset ? 'Admin' : 'Editor' }}
                            </p>
                            <!--                      Label with the relative date-->
                            <p class="mt-2 text-xs xt-gray-300 dark:text-gray-400 dark:border-gray-700">
                                Signed up {{ calculateDate(member.activatedAt) }}
                            </p>
                        </div>
                    </div>

                </div>
            </template>
            <template #buttons>
                <button
                        v-if="canManage"
                        class="button primary"
                        @click="onEditMembers"
                >
                    <span v-if="editingMembers">Done</span>
                    <span v-else>Manage Members</span>
                </button>
            </template>
        </LTabBody>
    </div>
</template>

<script>
import LTabBody from "@/components/layout/LTabBody";
import {computed, inject, ref, watch} from "vue";
import SuccessMessage from "@/components/forms/SuccessMessage.vue";
import {useDocument} from "@/composables/useDocument";
import config from "../../../../config";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {useAssetUsers} from "@/composables/useAssetUsers";

const {permissionThresholds} = config

export default {
    name: "OrganisationMembers",
    components: {
        SuccessMessage,
        LTabBody,
    },
    props: {
        organisationId: {
            type: String,
            required: true,
        },
        successMessage: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const dayJS = inject("DAYJS");
        const editingMembers = ref(false);
        const router = useRouter()
        const store = useStore()
        const {organisation} = useDocument('Organisation', props.organisationId)
        const canManage = computed(() => organisation.value._permissions >= permissionThresholds.manageAsset)
        const {documents, loadDocuments} = useAssetUsers('Organisation', props.organisationId, {refresh: true})

        const memberList = computed(() => {
            return documents.value.filter(
                person =>
                    person.activatedAt &&
                    (
                        person._userPermission === permissionThresholds.manageAsset ||
                        person._userPermission < permissionThresholds.maintainAsset
                    ) ||
                    person.repoId === organisation.value?.createdBy
            );
        });

        loadDocuments(true)

        const calculateDate = (date) => dayJS(Number(date)).fromNow();

        watch(() => router.currentRoute.value.query.modal, (modal) => {
            if (modal === 'ManageMembers') {
                editingMembers.value = canManage.value;
            } else {
                editingMembers.value = false;
            }
        })
        const onEditMembers = () => {
            if (editingMembers.value) {
                editingMembers.value = false;
                router.push({query: {}})
            } else {
                editingMembers.value = canManage.value;
                router.push({query: {
                  modal: 'ManageMembers',
                    organisationId: props.organisationId
                }})
            }
        };

        return {
            canManage,
            documents,
            editingMembers,
            manageAsset: permissionThresholds.manageAsset,
            memberList,
            onEditMembers,
            store,
            calculateDate

        };
    },
};
</script>

<style scoped></style>
