<template>
  <div class="SuccessMessage" v-if="message">
    <Transition>
          <div class="flex bg-white shadow rounded-2xl p-4 k2:p-10 justify-center flex-row">
            <img src="succesIcon.svg" alt="Successfull!">
            <div class="flex flex-col ml-3 k2:ml-6 justify-start">
              <div class="cardHeader">
                {{ title }}
              </div>
              <div>
                <slot>
                <span class="text-xs k2:text-sm">
                  {{ message }}
                </span>
                </slot>
              </div>
            </div>
          </div>
    </Transition>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  name: "SuccessMessage",
  props: {
    title: {
      type: String,
      default: "Success!",
    },
  },
  setup() {
    const store = useStore();
    const message = computed(() => store.state.UI.message);
    setTimeout(() => {
      store.state.UI.message = ""
    }, 5000);

    return {
      state: store.state,
      message,
    };
  },

}
</script>

<style scoped>

</style>